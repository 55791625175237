import React from 'react'
import ReactDOM from 'react-dom/client'

// style
import './assets/style/style.scss'

//content
import App from './App'

// security
// import reportWebVitals from './reportWebVitals'
// reportWebVitals()

const root = ReactDOM.createRoot(document.getElementById('Distriboissons'))
root.render(
  <React.StrictMode>
  <App />
  </React.StrictMode>
  )