/* PICTOS */
import logo from '../assets/img/logo.png'
import burger from '../assets/icon/burger.svg'
import close from '../assets/icon/close.svg'
import promo from '../assets/icon/promo.svg'
import cafes from '../assets/icon/cafes.svg'
import factory from '../assets/icon/factory.svg'
import product_blue from '../assets/icon/product_blue.svg'
import about from '../assets/icon/about.svg'
import bottles from '../assets/icon/bottles.svg'
import hands from '../assets/icon/hands.svg'
import vin from '../assets/icon/vin.svg'
import biere from '../assets/icon/biere.svg'
import table from '../assets/icon/table.svg'
import carte_perso from '../assets/icon/carte.svg'
import promo_blue from '../assets/icon/promo_blue.svg'
import contact from '../assets/icon/contact.svg'
import pin from '../assets/icon/pin.svg'
import users from '../assets/icon/users.svg'
import plateau from '../assets/icon/plateau.svg'
import commandes_icon from '../assets/icon/commandes.svg'
import preparation from '../assets/icon/preparation.svg'
import chargement from '../assets/icon/chargement.svg'
import livraison from '../assets/icon/livraison.svg'
import paiement from '../assets/icon/paiement.svg'
import degustations_blue from '../assets/icon/degustations.svg'
import news from '../assets/icon/news.svg'
import carte_privilege from '../assets/icon/carte_privilege.svg'
import chargement_white from '../assets/icon/chargement_white.svg'
import assortiment from '../assets/icon/assortiment.svg'
import pro from '../assets/icon/pro.svg'

import team from '../assets/img/team.jpg'
import newsletter from '../assets/img/newsletter.jpg'
import facebook from '../assets/img/facebook.png'
import carte from '../assets/img/carte.png'

/* services */
import dario from '../assets/img/dario.png'
import resto from '../assets/img/resto.jpg'
import bouchon from '../assets/img/bouchon.jpg'
import fut from '../assets/img/fut.jpg'
import degustations from '../assets/img/degustations.jpg'
import cafes_a_louer from '../assets/img/cafes.jpg'
import carteprivilege from '../assets/img/carte_privilege.jpg'
import chargement_service from '../assets/img/chargement.jpg'
import degustations_particulier from '../assets/img/degustations_particulier.jpg'
import invendus from '../assets/img/invendus.jpg'
import panier from '../assets/img/panier.jpg'

/* PRODUCTS */
import bieres from '../assets/img/bieres.jpg'
import alcools from '../assets/img/alcools.jpg'
import vins from '../assets/img/vins.jpg'
import snacks from '../assets/img/snacks.jpg'
import softs from '../assets/img/softs.jpg'
import festivites from '../assets/img/festivites.jpg'
import cafes_1 from '../assets/img/cafes_1.jpg'
import cafes_2 from '../assets/img/cafes_2.png'

/* SLIDER */
import home_1 from '../assets/img/slide/home_1.jpg'
import home_2 from '../assets/img/slide/home_2.jpg'
import home_3 from '../assets/img/slide/home_3.jpg'
import home_4 from '../assets/img/slide/home_4.jpg'
import commandes from '../assets/img/slide/commandes.jpg'
import horeca from '../assets/img/slide/horeca.jpg'
import contact_slide from '../assets/img/slide/contact.jpg'
import service_1 from '../assets/img/slide/service_1.jpg'
import service_2 from '../assets/img/slide/service_2.jpg'
import service_3 from '../assets/img/slide/service_3.jpg'
import service_4 from '../assets/img/slide/service_4.jpg'
import produit from '../assets/img/slide/produit.jpg'
import magasin_1 from '../assets/img/slide/magasin_1.jpg'
import magasin_2 from '../assets/img/slide/magasin_2.jpg'
import magasin_3 from '../assets/img/slide/magasin_3.jpg'
import magasin_4 from '../assets/img/slide/magasin_4.jpg'
import magasin_5 from '../assets/img/slide/magasin_5.jpg'

import service_pro_1 from '../assets/img/slide/service_pro_1.jpg'
import service_pro_2 from '../assets/img/slide/service_pro_2.jpg'
import service_pro_3 from '../assets/img/slide/service_pro_3.jpg'

const content = {
  global : {
    logo: logo,
    burger: burger,
    close: close,
    title: "Distriboissons",
    entrepriseName: "DISTRI BOISSONS scrl",
    adress: "Rue Montavaux, 125",
    area: "7080 Frameries",
    land: "Belgique",
    tel: "+32 (0)65 71 45 45",
    mail: "info@distriboissons.be",
    TVA: "BE 0663 754 172",
    facebook: "https://www.facebook.com/Drinks.DistriBoissons/"
  },
  imgs: {
    team: team,
    newsletter: newsletter,
    facebook: facebook,
    carte : carte,
    dario : dario,
    resto : resto,
    bouchon : bouchon,
    fut : fut,
    degustations : degustations,
    cafes_a_louer : cafes_a_louer,
    home_2 : home_2,
    commandes : commandes,
    cafes_1: cafes_1,
    cafes_2: cafes_2,
    pin: pin,
    carteprivilege : carteprivilege,
    chargement_service: chargement_service,
    degustations_particulier: degustations_particulier,
    invendus : invendus,
    logo: logo,
    burger: burger,
    close: close,
    panier: panier,
    pro : pro,
    users: users,
    mail: news,
  },
  menuPrimary: {
    links: ["Accueil", "Services", "Produits", "Promos Horeca", "Commandes online", "Contact / Magasins"],
    href: ["/pro", "/services-professionnels", "/produits-professionnels", "/promos-horeca", "/commandes", "/contact-professionnels"],
    title: ["Pro", "Services Professionnels", "Produits Professionnels", "Promos Horeca", "Commandes", "Nous contacter"]
  },
  menuSecondary: {
    links: ["Accueil", "Services", "Produits", "Magasins", "Promos Drinks", "Dégustations", "Contact / Magasins"],
    href: ["/particulier", "/services", "/produits", "/magasins", "/promos-drinks", "/degustations", "/contact"],
    title: ["Particulier", "Services", "Produits", "Magasins", "Promos Drinks", "Dégustations", "Nous contacter"]
  },
  slider1 : {
    slides : [service_pro_1,service_pro_2,service_pro_3],
    title: "Horeca / Professionnels"
  },
  slider_home_particulier : {
    slides : [home_1,home_2,home_3,home_4],
    title: "Drinks / Particulier"
  },
  slider_service : {
    slides : [service_1,service_2,service_3,service_4],
    title: "Services professionnels"
  },
  slider_horeca : {
    slides : [horeca],
    title: "Promos Horeca"
  },
  slider_drinks : {
    slides : [horeca],
    title: "Promos Drinks"
  },
  slider_produit : {
    slides : [produit],
    title: "Nos produits"
  },
  slider_contact : {
    slides : [contact_slide],
    title: "Nous contacter"
  },
  slider_commandes : {
    slides : [commandes],
    title: "Commande en ligne"
  },
  slide_magasins : {
    slides : [magasin_1,magasin_2,magasin_3,magasin_4,magasin_5],
    title: "Nos magasins"
  },
  degustation : {
    slides : [degustations],
    title: "Dégustations"
  },
  footer: {
    title : "INSCRIVEZ-VOUS a notre newsletter Horeca",
    subtitle : "Inscription newsletter Horeca",
    textButton: "S'inscrire"
  },
  acceuil: {
    icon: plateau,
    title: "Horeca / Professionnels",
    text: "Notre collaboration avec les professionnels de l’Horeca reprend. Découvrez nos services et nos promotions uniquement dédiées aux professionnels.",
    textButton : "Nos services",
    link : "/pro",
    classButton: "button"
  },
  acceuil_particulier: {
    icon: users,
    title: "Drinks / Particuliers",
    text: "Vous avez besoin de boissons, vous trouverez chez nous le bon conseil en bière en vin ou en alcool mais aussi un vaste choix de paniers cadeaux.",
    textButton : "Entrez",
    link : "/particulier",
    classButton: "button button--secondary"
  },
  carte_avantage : {
    icon: carte_privilege,
    title: "carte privilege",
    text: "Sur simple demande et toujours disponible, vous cumulez des points selon vos achats. Par exemple, à l’achat d’un casier de Cuvée des Trolls, vous recevez déjà 24 points.<br></br><ul><li>LA CARTE EST ENTIEREMENT GRATUITE</li><li>CUMULEZ DES POINTS : 100 points = € 5,00 en bon d’achat</li></ul>",
    textButton : "",
    link : "",
    classButton: "",
  },
  particulier_intro : {
    icon: carte_privilege,
    title: "Services",
    text: "Distri Boissons vous offre un service de qualité : choix, conseils, carte privilège ...",
    textButton : "Entrez",
    link : "/services",
    classButton: "button button--secondary",
  },
  chargement : {
    icon: chargement_white,
    title: "CHARGEMENT PAR NOS SOINS",
    text: "Le déchargement de vos vidanges, le chargement de votre commande... Tout est fait par nos soins.",
    textButton : "",
    link : "",
    classButton: "",
  },
  vin_particulier: {
    icon: vin,
    title: "CONSEILS SUR LES VINS",
    text: "En toutes circonstances, nous trouverons le produit qui s’accordera au mieux avec vos plats.",
    textButton : "",
    link : "",
    classButton: ""
  },
  degustations_particulier: {
    icon: about,
    title: "CONSEILS",
    text: "Vous avez besoin d’un conseil, nous sommes à votre disposition, que ce soit pour l’accompagnement d’une boisson avec son plat ou pour vos festivités mais aussi pour la réalisation d’un panier cadeau.",
    textButton : "Nous contacter",
    link : "/contact",
    classButton: "button button--secondary"
  },
  assortiment: {
    icon: assortiment,
    title: "CHOIX D’ASSORTIMENT",
    text: "Une large gamme de bières, vins, gins, alcools spéciaux, softs, paniers cadeau est à votre disposition dans nos boutiques.",
    textButton : "",
    link : "",
    classButton: ""
  },
  invendus: {
    icon: bottles,
    title: "REPRISE DES INVENDUS",
    text: "Vous organisez une fête et nous ne savez pas ce dont vous avez besoin... Pas de soucis, nous sommes à votre service pour vous conseiller et nous reprenons les casiers non-entamés.",
    textButton : "",
    link : "",
    classButton: ""
  },
  panier: {
    icon: plateau,
    title: "Paniers cadeau",
    text: "De stock en permanence ou sur commande selon votre demande, vous trouverez toujours une idée cadeau chez Distri Boissons selon votre budget.",
    textButton : "",
    link : "",
    classButton: ""
  },
  block1: {
    icon: promo,
    title: "LES PROMOS horeca",
    text: "Découvrez les promotions actuelles de l’ensemble de notre gamme HORECA.",
    textButton : "Les promos",
    link : "/promos-horeca",
    classButton: "button button--secondary"
  },
  block2: {
    icon: cafes,
    title: "Decouvrez nos cafes a louer",
    text: "Vous êtes à la recherche d’une exploitation HORECA, nous avons régulièrement des nouvelles opportunités.",
    textButton : "Plus d'infos",
    link : "/cafes-a-louer",
    classButton: "button"
  },
  block3: {
    icon: product_blue,
    title: "Nos produits",
    text: "",
    textButton : "",
    link : "/contact",
    classButton: ""
  },
  block4: {
    icon: factory,
    title: "SIEGE social / DEPOT central",
    text: "Rue Montavaux 125 7080 Frameries Belgique",
    textButton : "065 71 45 45",
    link : "/contact",
    classButton: "button button--secondary"
  },
  block5: {
    icon: about,
    title: "À propos",
    text: "Créé en 2016 par 3 négoces historiques de boissons, la Distri Dubuisson à Pipaix, la brasserie Kapp à Havré(Mons) et la brasserie Leveugle à Herseaux (Mouscron), la plateforme Distriboissons compte plus de 850 clients dans toute la province de Hainaut et du Brabant, allant des cafés de proximité ou des brasseries tendances aux collectivités et aux restaurants étoilés ou pas.",
    textButton : "",
    link : "/contact",
    classButton: ""
  },
  block6: {
    icon: promo_blue,
    title: "CONSULTEZ NOS PROMOS",
    text: "Chaque mois, nous avons des promotions vous permettant de faire vos achats avec des remises ou des verres gratuits. Retrouvez nos promos Horeca dans quelques semaines.",
    textButton : "",
    link : "/promos-horeca",
    classButton: ""
  },
  block7: {
    icon: news,
    title: "RECEVEZ LES PROMOS HORECA PAR MAIL",
    text: "Inscrivez-vous à notre newsletter",
    textButton : "S'inscrire",
    link : "/newsletter",
    classButton: "button"
  },
  promo_drink: {
    icon: promo_blue,
    title: "CONSULTEZ NOS PROMOS DRINKS",
    text: "Chaque mois, nous avons des promotions vous permettant de faire vos achats avec des remises ou des verres gratuits. Retrouvez nos promos drinks dans quelques semaines.",
    textButton : "",
    link : "",
    classButton: ""
  },
  promo_drink_home: {
    icon: promo,
    title: "CONSULTEZ NOS PROMOS DRINKS",
    text: "Découvrez les promotions actuelles de l’ensemble de notre gamme PARTICULIERS.",
    textButton : "Plus d'infos",
    link : "/promos-drinks",
    classButton: "button"
  },
  block8: {
    icon: preparation,
    title: "Commande en ligne",
    text: "Bientôt en ligne ...",
    textButton : "",
    link : "/contact",
    classButton: ""
  },
  block_services: {
    icon: hands,
    title: "Services",
    text: "Distri Boissons vous livre une gamme complète pour l’HORECA, les collectivités, les festivités et les clubs sportifs.",
    textButton : "Plus d'infos",
    link : "/services-professionnels",
    classButton: "button button--secondary"
  },
  contact: {
    icon: contact,
    title: "Contactez-nous",
    text: "",
    textButton : "",
    link : "/contact",
    classButton: ""
  },
  magasins: {
    icon: pin,
    title: "Nos differents magasins",
    text: "Il y a toujours un de nos points de vente pas loin de chez vous.",
    textButton : "",
    link : "/contact",
    classButton: ""
  },
  biere: {
    icon: biere,
    title: "Formation debit",
    text: "Lors de cette formation, nous vous apprendrons à servir correctement une bière au fût et à la bouteille dans la tradition des brasseurs de notre pays.",
    textButton : "Nous contacter",
    link : "/contact",
    classButton: "button"
  },
  degustations: {
    icon: biere,
    title: "Degustations/ Distri Academy",
    text: "Formation de votre personnel sur les vins et spiritueux, sur leurs accords mets, aux techniques de service. Organisation de Master Class ou de « Repas dégustation » pour vos clients dans votre établissement. Faites confiance à notre spécialiste Vins et Spiritueux.",
    textButton : "Plus d'infos",
    link : "/degustations",
    classButton: "button button--secondary"
  },
  degustation_page: {
    icon: degustations_blue,
    title: "Degustations / Distri Academy",
    text: "Venez à notre Distri Academy",
    textButton : "",
    link : "",
    classButton: ""
  },
  vin: {
    icon: vin,
    title: "Conseils en vin",
    text: "La possibilité de créer, dans un climat de confiance avec votre distributeur, une carte des vins personnalisée et adaptée à votre établissement.<br></br><ul><li>- Un large éventail de vins rigoureusement sélectionné par une équipe de professionnels.</li><li>- Une conception adaptée à vos besoins.</li><li>- Un accompagnement professionnel.</li></ul>",
    textButton : "",
    link : "/contact",
    classButton: ""
  },
  carte: {
    icon: carte_perso,
    title: "Votre carte tarif personnalisee",
    text: "Réalisation de vos cartes personnalisées par nos soins. Contactez notre bureau",
    textButton : "Plus d'infos",
    link : "/contact",
    classButton: "button"
  },
  dario: {
    icon: table,
    title: "Prêt de matériel de festivités",
    text: "Nous livrons également vos festivités.<br></br>Nous mettons à votre disposition du matériel de qualité, pour plus d’infos, prenez contact avec nos commerciaux.",
    textButton : "Plus d'infos",
    link : "https://dariolocation.be/",
    classButton: "button button--secondary"
  },
  faq1: {
    titles: ["Bières","Spiritueux","Vins","Snacks","Softs","Festivités"],
    contents: [
      "De la blonde à la brune, de la pils à la trappiste, de la nationale à la régionale, nous vous proposons un vaste choix de bières en fûts ou en casiers.",
      "Une très large gamme pour l’HORECA disponible en gros ou par bouteille. Nous avons une gamme premier prix mais aussi un choix important d’alcools premium.",
      "En importation directe de France ou d’Italie , notre sélection débute du premier prix au vin haut de gamme , que ce soit en bouteille ou en cubi.<br></br>Nous vous proposons également de découvrir les vins du monde.",
      "Nous proposons un large assortiment de biscuits, chips, café, lait, soupes, thé, cacahuètes, ...<br></br>Découvrez toute notre selection de snacks!<br></br><a href='https://distriboissons.be/snack/' class='button'>Plus d'infos</a>",
      'Nous travaillons avec les plus grandes marques mais nous proposons aussi des produits "premier prix" de bonne qualité.',
      "Nous livrons également vos festivités.<br></br>Nous mettons à votre disposition du matériel de qualité, pour plus d’infos, prenez contact avec nos commerciaux.",
    ],
    imgs: [bieres,alcools,vins,snacks,softs,festivites],
  },
  faq2: {
    titles: ["Commandez","Preparation","Chargement","Livraison","Paiement"],
    contents: [
      "Vous avez la possibilité de passer votre commande par mail ou par téléphone, soit en prenant contact directement avec la télévente soit en demandant que l’on vous contacte.<br></br>Il est également possible de faire celle-ci On Line.<br></br>Votre commande devra cependant toujours nous parvenir la veille de votre jour de livraison avant 12h00.",
      "A partir de 13h00, votre commande passe en préparation dans les mains de notre équipe de magasiniers.",
      "Une fois votre commande prête, elle sera contrôlée avant d’être chargée sur le camion faisant votre région.",
      "Nos camions partent tôt le matin afin de suivre le planning et le respect des heures de livraison.<br></br>Un rangement de votre commande sera fait avec soin dans vos installations en tenant compte de la rotation de vos stocks.",
      'Saviez-vous que nous vous offrons la possibilité d’effectuer vos paiements via un terminal mobile de paiement : C’est en effet bien plus sécurisant et plus facile à suivre pour votre comptabilité.',
    ],
    imgs: [commandes_icon,preparation,chargement,livraison,paiement],
  },
  faq3: {
    titles: ["Erquelinnes","Frameries","Gosselies","La Louvière","Herseaux","Tournai","Chapelle","Lobbes","Gerpinnes"],
    0:
    {
      horaire: "Du Lundi au Samedi de 9h00 à 13h00 et de 13h30 à 18h30<br></br>Le Dimanche de 9h00 à 12h30",
      tel: "071 59 59 54",
      email: "drinks.erquelinnes@distriboissons.be",
      adresse: "RUE DE MERBES 9</br>6560 ERQUELINNES",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2547.9116766974967!2d4.129090792400149!3d50.31224032399866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c23f8141b6cfff%3A0x2ab3c248644d03d2!2sRue%20de%20Merbes%209%2C%206560%20Erquelinnes!5e0!3m2!1sfr!2sbe!4v1739278935752!5m2!1sfr!2sbe"
    },
    1 : 
    {
      horaire: "Du Lundi au Samedi de 9h00 à 13h00 et de 13h30 à 18h00<br></br>Le Dimanche de 9h00 à 12h00",
      tel: "065 71 45 44",
      email: "drinks.frameries@distriboissons.be",
      adresse: "AVENUE DE L’EUROPE 1</br>7080 FRAMERIES",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2542.542795143648!2d3.9130425768487545!3d50.41235779010547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c25ae3a77108a3%3A0x185f43e27f8691a!2sAv.%20de%20l;Europe%201%2C%207080%20Frameries!5e0!3m2!1sfr!2sbe!4v1739279673629!5m2!1sfr!2sbe"
    },
    2 : 
    {
      horaire: "Le Lundi de 9h00 à 13h00 et de 13h30 à 18h00<br></br>Fermé le mardi<br></br>Mercredi et Jeudi de 9h00 à 13h00 et de 13h30 à 18h00<br></br>Vendredi et Samedi de 9h00 à 13h00 et de 13h30 à 18h30<br></br>Le Dimanche de 9h00 à 12h00<br></br>",
      tel: "071 814.736",
      email: "drinks.gosselies@distriboissons.be",
      adresse: "Rue Robesse 28</br>6041 Gosselies",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2538.588832981605!2d4.450074100000001!3d50.48599780000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c229334b25b75b%3A0xf4e3d8c9d2be7880!2sRue%20Robesse%2028%2C%206041%20Charleroi!5e0!3m2!1sfr!2sbe!4v1739279047951!5m2!1sfr!2sbe"
    },
    3 : 
    {
      horaire: "Du Lundi au Samedi de 9h00 13h00 et de 13h30 à 18h30<br></br>Le Dimanche de 9h00 à 12h00",
      tel: "064 55 65 63",
      email: "drinks.hstpaul@distriboissons.be",
      adresse: "CHAUSS. DE REDEMONT 24, 7100 HAIN</br>SAINT PAUL",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2539.571159852925!2d4.2057094768513075!3d50.46770988612046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2370f46e501f9%3A0x5d43ff64b158d6d!2sDrinks%20Distri%20Boissons%20-%20Haine-St-Paul!5e0!3m2!1sfr!2sbe!4v1739279096264!5m2!1sfr!2sbe"
    },
    4 : 
    {
      horaire: "Du Lundi au Jeudi de 9h00 à 13h00 et de 13h30 à 18h00<br></br>Du vendredi au Samedi de 9h00 à 13h00 et de 13h30 à 18h30<br></br>Le Dimanche de 9h00 à 12h00",
      tel: "056 33 28 58",
      email: "drinks.herseaux@distriboissons.be",
      adresse: "RUE DES CROISIERS 55</br>7712 HERSEAUX",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.9580244243366!2d3.2350912!3d50.72071789999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c325deddb2eee1%3A0x191b9b30264b2cf6!2sRue%20des%20Croisiers%2055%2C%207712%20Mouscron!5e0!3m2!1sfr!2sbe!4v1739279127182!5m2!1sfr!2sbe"
    },
    5 : 
    {
      horaire: "Du Lundi au Samedi de 9h00 à 13h00 et de 13h30 à 18h30<br></br>Le Dimanche de 9h00 à 12h30",
      tel: "069 84 11 56",
      email: "drinks.tournai@distriboissons.be",
      adresse: "BOUL. EISENHOWER 119</br>7500 TOURNAI",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2531.6841101275886!2d3.3930543768580796!3d50.61440707554464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2e00c1e1a7551%3A0xeba2a1b629b2a18!2sBd%20Eisenhower%20119%2C%207500%20Tournai!5e0!3m2!1sfr!2sbe!4v1739279172455!5m2!1sfr!2sbe"
    },
    6 :
    {
      horaire: "Du Mardi au Vendredi de 9h30 à 13h00 et de 13h30 à 18h30<br></br>Le Samedi de 9h30 à 13h00 et de 13h30 à 17h30",
      tel: "064 45 02 75",
      email: "drinks.chapelle@distriboissons.be",
      adresse: "RUE DES BLÉS 10A</br>7160 Chapelle-lez-Herlaimont",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5078.764564070427!2d4.268475076851427!3d50.471226585867335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c230dc18b80a19%3A0x32758636fb0431d6!2sRue%20des%20Bl%C3%A9s%2010%2C%207160%20Chapelle-lez-Herlaimont!5e0!3m2!1sfr!2sbe!4v1739279213750!5m2!1sfr!2sbe"
    },
    7 : 
    {
      horaire: "Du Mardi au Samedi de 9h00 à 13h00 et de 13h30 à 18h00<br></br>Dimanche et Lundi : fermé",
      tel: "071 59 00 45",
      email: "Drinks.lobbes@distriboissons.be",
      adresse: "RUE DES NERVIENS 3</br>6540 Lobbes",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2545.437522205586!2d4.281046100000001!3d50.35839569999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c23b823999a8cf%3A0x470e3958a813b58f!2sRue%20des%20Nerviens%203%2C%206540%20Lobbes!5e0!3m2!1sfr!2sbe!4v1739279288124!5m2!1sfr!2sbe"
    },
    8 : 
    {
      horaire: "Du Mardi au Samedi de 9h00 à 13h00 et de 13h30 à 18h00<br></br>Fermé le Dimanche et le Lundi",
      tel: "071/77 49 75",
      email: "drinks.gerpinnes@distriboissons.be",
      adresse: "RUE DE CHATELET 83A</br>6280 GERPINNES",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2545.4724036753496!2d4.5591693!3d50.3577452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c189940de57bf3%3A0x697b07ddac8cf6ef!2sRue%20de%20Ch%C3%A2telet%2083a%2C%206280%20Gerpinnes!5e0!3m2!1sfr!2sbe!4v1739279328799!5m2!1sfr!2sbe"
    }
  },
  list1 : {
    icons: [hands,factory,bottles],
    contents: [
      "Permettre aux particuliers de profiter de notre expérience et du vaste assortiment que l’on retrouve dans nos établissements Horeca est ce que Distriboissons souhaite proposer à la clientèle qui fréquente nos drinks.",
      "Que ce soit à Tournai, Frameries, Erquelinnes, Haine Saint-Paul (La Louvière), Gosselies, Herseaux (Mouscron) et Chapelle-Lez-Herlaimont, il y a toujours un point de vente pas loin de chez vous.",
      "Nous n’avons désormais de cesse de compléter notre assortiment avec les nouvelles bières qui apparaissent régulièrement, de vous sélectionner des produits tendances et d’être attentif au développement du marché."
    ]
  },
  cafes_1 : {
    title : "La Terasse",
    adresse : "Chaussée de Lille 317 7700 MOUSCRON",
    tel : null,
    img: "cafes_1",
    iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2524.246595513193!2d3.1852892768644923!3d50.75246106557213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c32f72fc057497%3A0x688b528585aea8b7!2sChau.%20de%20Lille%20317%2C%207700%20Mouscron!5e0!3m2!1sfr!2sbe!4v1738593938862!5m2!1sfr!2sbe"
  },
  cafes_2 : {
    title : "Le Trait d’Union",
    adresse : "8, Rue royale 7500 Tournai",
    tel : "0496/ 48 74 21",
    img: "cafes_2",
    iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2531.9815442235904!2d3.388879376857798!3d50.60888047594339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2e074d02bbb5d%3A0x28e7397ecb7b1fd0!2sRue%20Royale%208%2C%207500%20Tournai!5e0!3m2!1sfr!2sbe!4v1738594103404!5m2!1sfr!2sbe"
  },
}

export default content