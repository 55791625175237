import React from 'react'

import Title from './Title'
import Slide from './Slide'
import Wrapper from './Wrapper'
import Block from './Block'
import Gmap from './Gmap'
import Faqs from './Faqs'
import List from './List'
import Img from './Img'

const Home = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slider1" />
        <Slide blockKey="slider1" />
      </section>

      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="block1" />
          </Wrapper>
        </div>

        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="block2" />
          </Wrapper>
        </div>
      </section>

      <section className="pt-16 text-center color_secondary">
        <Block blockKey="block3" />
      </section>

      <section>
        <Faqs blockKey="faq1" />
      </section>

      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="block5" />
            <List blockKey="list1" />
          </Wrapper>
        </div>

        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="block_services" />
          </Wrapper>
          <Img blockKey="team" />
        </div>
      </section>

      <section className="md:flex items-center">
        <div className="md:w-1/2">
          <Gmap url={null} />
        </div>
        <div className="md:w-1/2 pt-4 text-center color_secondary">
          <Block blockKey="block4" />
        </div>
      </section>
    </>
  )
}

export default Home
