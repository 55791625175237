import React from 'react'
import content from '../data/content'

const BlockBg = ({ blockKey }) => {
  const src = content.imgs[blockKey]

  return (
    <div
      aria-hidden="true"
      className="max-xl md:mx-auto blockBg"
      style={{ backgroundImage: `url(${src})` }}
    ></div>
  )
}

export default BlockBg
