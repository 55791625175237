import React, { useState, useEffect } from 'react'
import content from '../data/content'
import ButtonPopup from './ButtonPopup'

const Footer = ({ blockKey }) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const blockData = content[blockKey]

  useEffect(() => {
    const interval = setInterval(
      () => {
        setCurrentYear(new Date().getFullYear())
      },
      1000 * 60 * 60 * 24 * 365
    )

    return () => clearInterval(interval)
  }, [])

  return (
    <footer className="flex items-center bg_secondary color_light">
      <img
        className="hidden md:block w-4/12"
        src={content.imgs.newsletter}
        title={content.global.title}
        alt={content.global.title}
      />
      <div className="w-7/12 md:w-6/12 p-8">
        <h4 className="text-2xl">{blockData.title}</h4>
        <ButtonPopup
          textButton={content.footer.textButton}
          style={`button--secondary button--small`}
        />
      </div>
      <div className="w-5/12 md:w-2/12 p-8 text-right">
        <a rel="noreferrer" target="_blank" href={content.global.facebook}>
          <img
            className="inline w-8/12"
            src={content.imgs.facebook}
            title={content.global.title}
            alt={content.global.title}
          />
        </a>
        <span className="block pt-8 title">Copyright {currentYear}</span>
      </div>
    </footer>
  )
}

export default Footer
