import React from 'react'

import Wrapper from './Wrapper'
import Block from './Block'
import Img from './Img'

const Acceuil = () => {
  return (
    <section className="color_light accueil">
      <section className="md:flex bg_primary items-end">
        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="acceuil" />
          </Wrapper>
          <Img blockKey="home_2" />
        </div>
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="acceuil_particulier" />
          </Wrapper>
          <Img blockKey="commandes" />
        </div>
      </section>
    </section>
  )
}

export default Acceuil
