import React from 'react'
import content from '../data/content'

const List = ({ blockKey }) => {
  const blockData = content[blockKey]

  return (
    <>
      {blockData.contents.map((text, index) => (
        <div key={index} className="mt-16 flex">
          <img
            width="32px"
            src={blockData.icons[index]}
            title={text}
            alt={text}
          />
          <p className="w-9/12 pl-4 text-left text-sm">{text}</p>
        </div>
      ))}
    </>
  )
}

export default List
