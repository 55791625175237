import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import years from '../assets/icon/18years.svg'
import content from '../data/content'

import Adress from './Adress'

const Splash = () => {
  const [message_visible, setVal] = useState(false)
  const messageVisivle = (e) => {
    e.preventDefault()
    setVal(true)
  }

  return (
    <section className="splash bg_secondary color_light">
      <div className="flex flex-wrap justify-center min-h-screen items-center">
        <div className="p-16 md:mx-auto text-center">
          <img
            className="inline-block mt-8 mb-10"
            width="140"
            src={content.global.logo}
            title="Distriboissons"
            alt="Distriboissons"
          />
          <h2 className="text-3xl font-semibold sm:text-4xl">
            Bienvenue chez Distri Boissons
          </h2>
          <img
            className="inline-block mt-4"
            width="32"
            src={years}
            title="Distriboissons"
            alt="Distriboissons"
          />
          <p className="mt-3 title text-2xl">Avez-vous plus de 18 ans ?</p>
          <nav className="mt-4 flex justify-center">
            <Link to="/accueil" className="button button--secondary ml-4 mr-4">
              Oui
            </Link>
            <Link
              onClick={messageVisivle}
              className="button button--secondary ml-4 mr-4"
            >
              Non
            </Link>
          </nav>
          {message_visible ? (
            <p className="mt-8 max-w-md color_primary title text-3xl">
              Dommage! Revenez à votre majorité, nous serons toujours présents.
            </p>
          ) : null}
        </div>
        <div className="w-12/12 text-left pl-8">
          <Adress data={content.global} />
        </div>
        <div className="text-center">
          <a href={content.global.facebook} target="_blank" rel="noreferrer">
            <img
              className="inline-block mt-4 mb-8"
              width="120"
              src={content.imgs.facebook}
              title="Distriboissons"
              alt="Distriboissons"
            />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Splash
