const Adress = ({ data }) => {
  const blockData = data

  return (
    <div className="xs:block lg:hidden xl:block mt-4 p-8">
      <h3 className="text-xl text-bold">{blockData.entrepriseName}</h3>
      <p className="mt-4">
        {blockData.adress}
        <br></br>
        {blockData.area}
        <br></br>
        {blockData.land}
        <br></br>
      </p>

      <p className="mt-4">
        {blockData.tel}
        <br></br>
        {blockData.mail}
        <br></br>
        {blockData.TVA}
        <br></br>
      </p>
    </div>
  )
}

export default Adress
