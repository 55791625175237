import React, { useState } from 'react'
import Input from './Input'
import { useLocation } from 'react-router-dom'
import content from '../data/content'

const FormTwoo = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT
  const [valueCheckbox, setValue] = useState(false)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const location = useLocation()
  const [formData, setFormData] = useState({
    name: '',
    fname: '',
    email_contact: '',
    message: '',
    type: content.menuPrimary.href.includes(location.pathname)
      ? 'pro'
      : 'particulier',
    baseurl: 'https://distriboissons.be',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const toggleCheckbox = () => {
    setValue(!valueCheckbox)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const formValues = Object.fromEntries(formData.entries())

    setLoading(true)
    setError(null)

    try {
      const response = await fetch(environment + '/data/api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      })

      const result = await response.json()

      if (result.status === 'ok') {
        setData(result.status)
      } else {
        setError(result.status)
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!data && (
        <form
          className="max-w-3xl m-auto text-left md:flex justify-between flex-wrap"
          onSubmit={handleSubmit}
        >
          <div className="md:w-4/12 md:pr-4 mb-4">
            <Input
              id="fname"
              label="Prénom"
              type="text"
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="md:w-4/12 md:pr-4 mb-4">
            <Input
              id="name"
              label="Nom"
              type="text"
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="md:w-4/12 md:pr-4 mb-4">
            <Input
              id="email_contact"
              label="Email"
              type="email"
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="w-full mb-4">
            <label className="block text-sm font-bold mb-1" htmlFor="message">
              Message
            </label>
            <textarea
              className="h-60 shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              name="message"
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="w-full mb-4">
            <input
              type="checkbox"
              id="checkbox"
              required={true}
              onChange={toggleCheckbox}
              checked={valueCheckbox}
            />
            <label htmlFor="checkbox">
              J'accèpte les conditions d'utulisation de mes données
              personnelles.
            </label>
          </div>
          <div className="w-full flex items-center justify-between">
            <input type="hidden" value={formData.type} name="type" id="type" />
            <input
              value={formData.baseurl}
              type="hidden"
              name="baseurl"
              id="baseurl"
            />

            <button
              disabled={!valueCheckbox}
              className="button button--secondary"
              type="submit"
            >
              Envoyer
            </button>
          </div>
        </form>
      )}
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {data && <div className="color_secondary">Message envoyé</div>}
    </>
  )
}

export default FormTwoo
