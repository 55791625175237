import React, { useState } from 'react'
import content from '../data/content'
import Gmap from '../components/Gmap'

const FaqTwoo = ({ blockKey }) => {
  const blockData = content[blockKey]
  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = (index) => {
    setActiveTab(index)

    var current = document.getElementById('tab-' + index)
    if (current) {
      window.scrollTo({
        top: current.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      <div className="max-w-6xl md:w-9/12 md:mx-auto p-8">
        <ul
          className="flex flex-wrap justify-center text-sm font-medium text-center w-full"
          role="tablist"
        >
          {blockData.titles.map((title, index) => (
            <li id={`tab-` + index} key={`tab-` + index} role="presentation">
              <button
                onClick={() => handleTabClick(index)}
                className={
                  activeTab === index
                    ? 'button button--tab'
                    : 'button button--tab inactif'
                }
              >
                {title}
              </button>
            </li>
          ))}
        </ul>
        <div className="max-w-4xl m-auto m-4 pt-12 text-sm md:flex justify-around items-start color_secondary">
          <div className="md:w-4/12 mb-8 pr-4 text-left">
            <h3 className="text-3xl">{blockData.titles[activeTab]}</h3>
            <ul>
              <li className="mb-4">
                <a href={`tel:` + blockData[activeTab].tel}>
                  {blockData[activeTab].tel}
                </a>
              </li>
              <li
                dangerouslySetInnerHTML={{
                  __html: blockData[activeTab].adresse,
                }}
              ></li>
              <li className="mb-4">Belgique</li>
              <li>
                <a href={`mailto:` + blockData[activeTab].email}>
                  {blockData[activeTab].email}
                </a>
              </li>
            </ul>
          </div>
          <div className="md:w-4/12">
            <p className="text-2xl title">Horaire</p>
            <p
              dangerouslySetInnerHTML={{ __html: blockData[activeTab].horaire }}
            ></p>
          </div>
          <div className="my-16 md:m-0 md:w-4/12 text-center">
            <a
              className="button button--secondary"
              href={`malto:` + blockData[activeTab].email}
            >
              Contactez-nous
            </a>
          </div>
        </div>
      </div>
      <>
        <Gmap
          title={blockData.titles[activeTab]}
          url={blockData[activeTab].iframe}
        />
      </>
    </>
  )
}

export default FaqTwoo
