import React from 'react'
import content from '../data/content'
import { Link } from 'react-router-dom'

import ButtonPopup from '../components/ButtonPopup'

const Block = ({ blockKey }) => {
  const blockData = content[blockKey]

  return (
    <>
      <div className="max-xl md:mx-auto">
        <img
          className="inline-block my-4"
          width="32"
          src={blockData.icon}
          alt={blockData.title}
          title={blockData.title}
        />
        <h2 className="text-3xl font-semibold sm:text-4xl">
          {blockData.title}
        </h2>
        <p
          className="mt-3 max-w-lg m-auto"
          dangerouslySetInnerHTML={{ __html: blockData.text }}
        ></p>
      </div>
      <div className="mt-4">
        {blockData.textButton && blockData.link !== '/newsletter' ? (
          <Link
            to={blockData.link}
            className={`inline-block my-4 ${blockData.classButton}`}
          >
            {blockData.textButton}
          </Link>
        ) : null}
        {blockData.link === '/newsletter' ? (
          <ButtonPopup
            textButton={content.footer.textButton}
            style={blockData.classButton}
          />
        ) : null}
      </div>
    </>
  )
}

export default Block
