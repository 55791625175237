import content from '../data/content'

const Img = ({ blockKey, classe }) => {
  const src = content.imgs[blockKey]
  const style = classe

  return (
    <img
      className={style ? style : ''}
      src={src}
      title={content.global.title}
      alt={content.global.title}
    />
  )
}

export default Img
