import content from '../data/content'

const Title = ({ blockKey }) => {
  const blockData = content[blockKey]

  return (
    <h1
      className="slider_title"
      dangerouslySetInnerHTML={{ __html: blockData.title }}
    ></h1>
  )
}

export default Title
