import React, { useState, useEffect } from 'react'

function Blog(props) {
  const type = props.type

  const environment = process.env.REACT_APP_ENVIRONMENT

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  function generateGoogleMapLink(dateString) {
    const encodedDate = encodeURIComponent(dateString)
    const googleMapLink = `https://www.google.com/maps?q=${encodedDate}`
    return googleMapLink
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(environment + '/data/' + type + '.php')

        if (!response.ok) {
          throw new Error('Problème de récupération des données')
        }

        const result = await response.json()
        setData(result)
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [environment, type])

  if (loading) {
    return <div>Chargement...</div>
  }

  if (error) {
    return <div>Erreur : {error}</div>
  }

  return (
    <section className="m-auto flex justify-center items-center flex-wrap">
      {data.map((article, index) => (
        <article
          id={`article-` + article.degustations_id}
          key={`article-` + index}
          className="m-4 bg-white border border-gray-200 rounded-lg shadow-sm md:flex-row md:max-w-sm hover:bg-gray-100"
        >
          <img
            className="rounded-t-lg md:rounded-none md:rounded-s-lg"
            src={
              'https://distriboissons.be/admin/img/degustations/' +
              article.degustations_img +
              '.' +
              article.degustations_format
            }
            alt={article.degustations_titre}
            title={article.degustations_titre}
          />
          <div className="p-5">
            <p className="text-sm color_secondary">
              {article.degustations_prix}
            </p>
            <h5
              className="mt-4 mb-2 text-2xl font-bold tracking-tight color_primary"
              dangerouslySetInnerHTML={{
                __html: article.degustations_description,
              }}
            ></h5>
            <p className="mb-3 font-normal text-gray-700">
              <a
                target="_blank"
                rel="noreferrer"
                href={generateGoogleMapLink(
                  article.degustations_adresse + '+' + article.degustations_cp
                )}
              >
                {article.degustations_adresse}
                <br></br>
                {article.degustations_cp}
                <br></br>
              </a>
              <br></br>
              <span className="color_secondary">
                {article.degustations_date}
              </span>
              <br></br>
              {article.degustations_horaire}
            </p>
            <a
              href="/contact"
              className="inline-flex m-4 items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg_primary focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Nous contacter
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href={article.degustations_facebook}
              className="inline-flex m-4 items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg_secondary focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Facebook
            </a>
          </div>
        </article>
      ))}
    </section>
  )
}

export default Blog
