import React from 'react'

import Slide from './Slide'
import Wrapper from './Wrapper'
import Block from './Block'
import Title from './Title'
import Blog from './Blog'

const Degustations = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="degustation" />
        <Slide blockKey="degustation" />
      </section>

      <section className="bg_light color_secondary">
        <Wrapper>
          <Block blockKey="degustation_page" />
        </Wrapper>
      </section>

      <Blog type="blog" />
    </>
  )
}

export default Degustations
