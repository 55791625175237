import React from 'react'

const Gmap = ({ url, title_iframe }) => {
  var title = 'Distriboissons - Siège social'

  var url_iframe =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2542.795165907785!2d3.909346376848551!3d50.40765489044387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c25ae2bebcfc9b%3A0xb1fc3c0c3225baa8!2sDrinks%20Distri%20Boissons!5e0!3m2!1sfr!2sbe!4v1737985832084!5m2!1sfr!2sbe'

  if (url) {
    url_iframe = url
    title = title_iframe
  }

  return (
    <iframe
      title={title}
      className="h-96 w-12/12 border-0"
      src={url_iframe}
      loading="lazy"
    ></iframe>
  )
}

export default Gmap
