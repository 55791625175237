import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Img from './Img'
import ButtonPopup from './ButtonPopup'
import Adress from './Adress'
import useWindowWidth from '../data/widthScreen'
import content from '../data/content'

const Menu = () => {
  const location = useLocation()
  const width = useWindowWidth()

  if (width > 1024) {
    var large = true
  } else {
    large = false
  }

  const [isOpen, setIsOpen] = useState(large)

  const handleBurgerMenu = () => {
    setIsOpen(!isOpen)
  }

  const mainMenu = content.menuPrimary.href.includes(location.pathname)
  var cta = 'particulier'
  var displayMenu = content.menuPrimary
  var textCta = 'Drinks/Particuliers'

  if (!mainMenu) {
    cta = 'pro'
    displayMenu = content.menuSecondary
    textCta = 'Horeca/Professionnels'
  }

  return (
    <>
      <header className="py-8 overflow-y-auto bg_secondary color_light lg:fixed lg:w-2/12 lg:h-screen">
        <div className="flex justify-between">
          <Link to="/accueil" className="w-1/4 md:w-1/2 md:mx-auto">
            <Img blockKey="logo" classe={'px-4 max-w-[132px] m-auto'} />
          </Link>
          <button
            className="lg:hidden w-1/6 md:mx-auto cursor-pointer"
            onClick={handleBurgerMenu}
          >
            {isOpen ? (
              <img
                className="px-4"
                width="64"
                src={content.global.close}
                title={content.global.title}
                alt={content.global.title}
              />
            ) : (
              <img
                className="px-4"
                width="64"
                src={content.global.burger}
                title={content.global.title}
                alt={content.global.title}
              />
            )}
          </button>
        </div>

        <menu className={isOpen ? 'block' : 'hidden'}>
          <nav className="pt-8 text-center ">
            {displayMenu.links.map((link, index) => (
              <Link
                key={index}
                onClick={large ? false : handleBurgerMenu}
                title={displayMenu.title[index]}
                className={
                  location.pathname === displayMenu.href[index]
                    ? 'header_link actif'
                    : 'header_link'
                }
                to={displayMenu.href[index]}
              >
                <span className="mx-4 text-lg title">{link}</span>
              </Link>
            ))}

            {!large ? (
              <>
                <Link
                  onClick={large ? false : handleBurgerMenu}
                  title="Distriboissons - Particulier"
                  className={
                    location.pathname === 'particulier'
                      ? 'header_link header_link--inlineBlock actif'
                      : 'header_link header_link--inlineBlock'
                  }
                  to="particulier"
                >
                  <span className="mx-4 text-lg title">Particulier</span>
                </Link>
                <span> / </span>
                <Link
                  onClick={large ? false : handleBurgerMenu}
                  title="Distriboissons - Pro"
                  className={
                    location.pathname === 'pro'
                      ? 'header_link header_link--inlineBlock actif'
                      : 'header_link header_link--inlineBlock'
                  }
                  to="pro"
                >
                  <span className="mx-4 text-lg title">Professionnels</span>
                </Link>
              </>
            ) : (
              false
            )}
          </nav>
          <Adress data={content.global} />
          <div className="my-4">
            <a target="_blank" rel="noreferrer" href={content.global.facebook}>
              <Img blockKey="facebook" classe={'m-auto w-4/12'} />
            </a>
          </div>
          <div className="p-4 skew">
            <h3>{content.footer.subtitle}</h3>
            <ButtonPopup
              textButton={content.footer.textButton}
              style={`button--primary button--small`}
            />
          </div>
        </menu>
      </header>
      <Link
        to={`/` + cta}
        className={
          cta === 'pro'
            ? 'bg_primary cta_switch hover:opacity-80 hidden lg:block absolute py-2 right-0 w-2/12 text-center origin-top scale-[0.75] z-99'
            : 'bg_secondary cta_switch hover:opacity-80 hidden lg:block absolute py-2 right-0 w-2/12 text-center origin-top scale-[0.75] z-99'
        }
      >
        <Img
          blockKey={cta === 'pro' ? 'pro' : 'users'}
          classe={'my-2 mx-auto w-[32px]'}
        />
        <span className="inline w-7/12 m-auto font-bold color_light uppercase">
          Accédez à la partie {textCta}
        </span>
      </Link>
    </>
  )
}

export default Menu
