import React from 'react'

import Slide from './Slide'
import Wrapper from './Wrapper'
import Block from './Block'
import BlockBg from './BlockBg'
import Faqs from './Faqs'
import FaqTwoo from './FaqTwoo'
import Title from './Title'
import Img from './Img'

const ServicesPro = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slider_service" />
        <Slide blockKey="slider_service" />
      </section>
      <Img classe="w-12/12 m-auto" blockKey="carte" />
      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="vin" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="bouchon" />
        </div>
      </section>
      <section className="md:flex items-stretch flex-row-reverse">
        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="biere" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="fut" />
        </div>
      </section>
      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="degustations" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="degustations" />
        </div>
      </section>
      <section className="md:flex items-stretch flex-row-reverse">
        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="carte" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="resto" />
        </div>
      </section>
      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="dario" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="dario" />
        </div>
      </section>
      <section className="pt-16 text-center color_secondary services">
        <Faqs blockKey="faq2" />
      </section>
      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="block2" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="cafes_a_louer" />
        </div>
      </section>
      <section className="pt-16 text-center color_secondary">
        <Block blockKey="magasins" />
      </section>
      <section className="pt-8 text-center color_secondary">
        <FaqTwoo blockKey="faq3" />
      </section>
    </>
  )
}

export default ServicesPro
