import React from 'react'

import Slide from './Slide'
import Block from './Block'
import FaqTwoo from './FaqTwoo'
import Title from './Title'
import Img from './Img'

const Magasins = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slide_magasins" />
        <Slide blockKey="slide_magasins" />
      </section>

      <Img classe="w-12/12 m-auto" blockKey="carte" />

      <section className="pt-16 text-center color_secondary">
        <Block blockKey="magasins" />
      </section>

      <section className="pt-8 text-center color_secondary">
        <FaqTwoo blockKey="faq3" />
      </section>
    </>
  )
}

export default Magasins
