import React from 'react'

import Title from './Title'
import Slide from './Slide'
import Article from './Article'

const Cafes = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slider_service" />
        <Slide blockKey="slider_service" />
      </section>

      <Article type="cafes" />
    </>
  )
}

export default Cafes
