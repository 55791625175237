import React from 'react'

import Slide from './Slide'
import Wrapper from './Wrapper'
import Block from './Block'
import Title from './Title'
import FaqTwoo from './FaqTwoo'
import Gmap from './Gmap'
import FormContact from './FormContact'

const Contact = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slider_contact" />
        <Slide blockKey="slider_contact" />
      </section>

      <section className="bg_light color_secondary">
        <Wrapper>
          <Block blockKey="contact" />
          <FormContact />
        </Wrapper>
      </section>

      <section className="md:flex items-center bg_secondary">
        <div className="md:w-1/2">
          <Gmap url={null} />
        </div>
        <div className="md:w-1/2 pt-4 text-center color_light">
          <Block blockKey="block4" />
        </div>
      </section>

      <section className="pt-16 text-center color_secondary">
        <Block blockKey="magasins" />
      </section>

      <section>
        <FaqTwoo blockKey="faq3" />
      </section>
    </>
  )
}

export default Contact
