const Input = (props) => {
  const { id, label, type, required } = props

  return (
    <>
      <label className="block text-sm font-bold mb-1" htmlFor={id}>
        {label}
      </label>
      <input
        className="border rounded w-full py-2 px-3 leading-tight focus:outline"
        id={id}
        name={id}
        type={type}
        placeholder={label}
        required={required}
      />
    </>
  )
}

export default Input
