import { useLocation } from 'react-router-dom'

const Head = () => {
  const location = useLocation()

  var paths = [
    '/pro',
    '/services-professionnels',
    '/produits-professionnels',
    '/promos-horeca',
    '/commandes',
    '/contact-professionnels',
    '/particulier',
    '/services',
    '/produits',
    '/magasins',
    '/promos-drinks',
    '/degustations',
    '/contact',
    '/cafes-a-louer',
  ]

  var titles = [
    'Pro',
    'Services Professionnels',
    'Produits Professionnels',
    'Promos Horeca',
    'Commandes',
    'Nous contacter',
    'Particulier',
    'Services',
    'Produits',
    'Magasins',
    'Promos Drinks',
    'Dégustations',
    'Nous contacter',
    'Cafés à louer',
  ]

  var title = 'Bienvenue'
  if (paths.includes(location.pathname)) {
    title = titles[paths.indexOf(location.pathname)]
  }

  return (
    <>
      <title>{`Distriboissons - ` + title}</title>
      <meta
        name="description"
        content="Distriboisssons, découvrez nos services et nos promotions tant pour les particuliers que pour les professionnels."
      />
      <meta name="robots" content="all" />
      <meta name="language" content="fr-BE" />
      <meta name="author" content="Arpeggio Communication" />
    </>
  )
}

export default Head
