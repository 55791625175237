import React, { createContext, useState } from 'react'

const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
  const [popupStatus, setIsPopupOpen] = useState(false)

  const setPopupStatus = () => {
    setIsPopupOpen((IsPopupOpen) => (IsPopupOpen === true ? false : true))
  }

  return (
    <ThemeContext.Provider value={{ popupStatus, setPopupStatus }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
