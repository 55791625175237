import React from 'react'

import Title from './Title'
import Slide from './Slide'
import Wrapper from './Wrapper'
import Block from './Block'
import Faqs from './Faqs'
import FaqTwoo from './FaqTwoo'
import List from './List'
import Img from './Img'

const HomeParticulier = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slider_home_particulier" />
        <Slide blockKey="slider_home_particulier" />
      </section>

      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="degustations_particulier" />
          </Wrapper>
        </div>

        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="promo_drink_home" />
          </Wrapper>
        </div>
      </section>

      <section className="pt-16 text-center color_secondary">
        <Block blockKey="block3" />
      </section>

      <section>
        <Faqs blockKey="faq1" />
      </section>

      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="block5" />
            <List blockKey="list1" />
          </Wrapper>
        </div>

        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="particulier_intro" />
          </Wrapper>
          <Img blockKey="degustations_particulier" classe={'w-12/12'} />
        </div>
      </section>

      <section className="pt-16 text-center color_secondary">
        <Block blockKey="magasins" />
      </section>

      <section className="pt-8 text-center color_secondary">
        <FaqTwoo blockKey="faq3" />
      </section>
    </>
  )
}

export default HomeParticulier
