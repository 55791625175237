import React from 'react'

import Slide from './Slide'
import Wrapper from './Wrapper'
import Block from './Block'
import Title from './Title'

const Commande = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slider_commandes" />
        <Slide blockKey="slider_commandes" />
      </section>

      <section className="bg_light color_secondary">
        <Wrapper>
          <Block blockKey="block8" />
        </Wrapper>
      </section>
    </>
  )
}

export default Commande
