import React, { useContext } from 'react'
import { ThemeContext } from '../data/contextPopup'

import Newsletter from './Newsletter'
import ButtonPopup from './ButtonPopup'

const Popup = () => {
  const { popupStatus } = useContext(ThemeContext)

  if (!popupStatus) return null

  window.scrollTo({
    top: 0,
  })

  return (
    <section className="popupOpen absolute z-999 w-full h-screen bg_primary md:h-screen">
      <div className="absolute left-1/2 top-1/2 w-[90%] max-w-3xl -translate-x-1/2 -translate-y-1/2 color_light md:h-96 lg:w-full text-right">
        <ButtonPopup />
        <div className="mx-auto flex max-w-xl flex-col p-10 md:py-20">
          <Newsletter />
        </div>
      </div>
    </section>
  )
}

export default Popup
