import React from 'react'

import Title from './Title'
import Slide from './Slide'
import Block from './Block'
import Faqs from './Faqs'

const Products = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slider_produit" />
        <Slide blockKey="slider_produit" />
      </section>

      <section className="pt-16 text-center color_secondary">
        <Block blockKey="block3" />
      </section>

      <section>
        <Faqs blockKey="faq1" />
      </section>
    </>
  )
}

export default Products
