import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import content from '../data/content'
import Input from './Input'
import Img from './Img'

const Newsletter = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT
  const [valueCheckbox, setValue] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const location = useLocation()
  const [formData, setFormData] = useState({
    mail_subscribe: '',
    type: content.menuPrimary.href.includes(location.pathname)
      ? 'pro'
      : 'particulier',
    baseurl: 'https://distriboissons.be',
  })

  const toggleCheckbox = () => {
    setValue(!valueCheckbox)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formValues = Object.fromEntries(formData.entries())

    setFormData({
      ...formData,
      formValues,
    })

    setLoading(true)
    setError(null)

    try {
      const response = await fetch(environment + '/data/newsletter.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      })

      const result = await response.json()

      if (result.status === 'ok') {
        setData(result.status)
      } else {
        setError(result.status)
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  if (data !== null)
    return (
      <div className="mt-8 w-12/12 text-center color_light">
        Inscription réussie !
      </div>
    )

  return (
    <>
      <form
        className="relative mb-4 max-w-full text-center newsletter"
        onSubmit={handleSubmit}
      >
        <Img classe="m-auto w-[48px]" blockKey="mail" />
        <h3 className="mb-2 text-2xl font-bold md:text-4xl text-center">
          Inscrivez-vous a notre newsletter
        </h3>
        <Input
          id="mail_subscribe"
          label="E-Mail"
          type="email"
          required={true}
        />
        <input type="hidden" value={formData.type} name="type" id="type" />
        <input
          value={formData.baseurl}
          type="hidden"
          name="baseurl"
          id="baseurl"
        />
        <div className="w-full mt-4 mb-4">
          <input
            type="checkbox"
            id="checkbox"
            required={true}
            onChange={toggleCheckbox}
            checked={valueCheckbox}
          />
          <label className="checkbox color_light" htmlFor="checkbox">
            J’aimerais recevoir des communications marketing de la part de
            Distri Boissons par e-mail.
          </label>
        </div>
        <p className="text-sm text-left mt-4 color_light text-gray-500"></p>
        <button disabled={!valueCheckbox} className="button" type="submit">
          Envoyer
        </button>
      </form>
      <div className="mt-4 text-center">
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error}</div>}
      </div>
    </>
  )
}

export default Newsletter
