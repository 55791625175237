import React, { useState } from 'react'
import content from '../data/content'

const Faqs = ({ blockKey }) => {
  const blockData = content[blockKey]
  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = (index) => {
    setActiveTab(index)

    var current = document.getElementById('faq-' + index)
    if (current) {
      window.scrollTo({
        top: current.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className="max-w-6xl md:w-9/12 md:mx-auto p-8">
      <ul
        id="faq"
        className="flex flex-wrap justify-center text-sm font-medium text-center w-full"
        role="tablist"
      >
        {blockData.titles.map((title, index) => (
          <li id={`faq-` + index} key={`faq-` + index} role="presentation">
            <button
              onClick={() => handleTabClick(index)}
              className={
                activeTab === index
                  ? 'button button--tab'
                  : 'button button--tab inactif'
              }
            >
              {title}
            </button>
          </li>
        ))}
      </ul>
      <div className="m-4 pt-4 text-sm md:flex items-center">
        <p
          className="md:w-5/12 mb-8 pr-4 color_secondary text-left"
          dangerouslySetInnerHTML={{ __html: blockData.contents[activeTab] }}
        />
        <img
          className="md:w-6/12 mb-8 p-8"
          src={blockData.imgs[activeTab]}
          title={blockData.titles[activeTab]}
          alt={blockData.titles[activeTab]}
        />
      </div>
    </div>
  )
}

export default Faqs
