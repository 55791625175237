import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <section className="splash bg_secondary color_light">
      <div className="flex flex-wrap justify-center min-h-screen items-center">
        <div className="p-16 md:mx-auto text-center">
          <h2 className="text-3xl font-semibold sm:text-4xl">404 error</h2>
          <Link to="/accueil" className="block mt-4 button">
            Retour
          </Link>
        </div>
      </div>
    </section>
  )
}

export default NotFound
