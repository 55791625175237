// src/components/SwiperSlider.js
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import content from '../data/content'

const Slide = ({ blockKey }) => {
  const blockData = content[blockKey]

  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      scrollbar={{ draggable: true }}
      centeredSlides={true}
      speed={2500}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      {blockData.slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="slide" style={{ backgroundImage: `url(${slide})` }}>
            <img
              className="hidden"
              src={slide}
              title={content.global.title}
              alt={content.global.title}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Slide
