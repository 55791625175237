// src/App.js
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from './data/contextPopup'

import Header from './components/Header'
import Home from './components/Home'
import Splash from './components/Splash'
import Accueil from './components/Accueil'
import Cafes from './components/Cafes'
import Services from './components/Services'
import ServicesPro from './components/ServicesPro'
import HomeParticulier from './components/HomeParticulier'
import Products from './components/Products'
import Promo from './components/Promo'
import PromoDrinks from './components/PromoDrinks'
import Commandes from './components/Commandes'
import Magasins from './components/Magasins'
import Degustations from './components/Degustations'
import Contact from './components/Contact-pro'
import Footer from './components/Footer'
import Popup from './components/Popup'
import Head from './components/Head'
import NotFound from './components/NotFound'

function App() {
  const base = process.env.REACT_APP_BASE

  return (
    <Router basename={base}>
      <ThemeProvider>
        <Head />
        <Popup />
        <div className="lg:flex">
          <Header />
          <main className="w-full lg:w-10/12">
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route exact path="/" element={<Splash />} />
              <Route index path="/accueil" element={<Accueil />} />
              <Route path="/pro" element={<Home />} />
              <Route
                path="/services-professionnels"
                element={<ServicesPro />}
              />
              <Route path="/produits-professionnels" element={<Products />} />
              <Route path="/promos-horeca" element={<Promo />} />
              <Route path="/commandes" element={<Commandes />} />
              <Route path="/contact-professionnels" element={<Contact />} />
              <Route path="/cafes-a-louer" element={<Cafes />} />
              <Route path="/particulier" element={<HomeParticulier />} />
              <Route path="/services" element={<Services />} />
              <Route path="/produits" element={<Products />} />
              <Route path="/magasins" element={<Magasins />} />
              <Route path="/promos-drinks" element={<PromoDrinks />} />
              <Route path="/degustations" element={<Degustations />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer blockKey="footer" />
          </main>
        </div>
      </ThemeProvider>
    </Router>
  )
}

export default App
