import React from 'react'

import Slide from './Slide'
import Wrapper from './Wrapper'
import Block from './Block'
import Title from './Title'

const Promos = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slider_horeca" />
        <Slide blockKey="slider_horeca" />
      </section>

      <section className="bg_light color_secondary">
        <Wrapper>
          <Block blockKey="block6" />
        </Wrapper>
      </section>

      <section className="bg_secondary">
        <iframe
          className="min-h-screen w-12/12 border-0"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Distriboissons - Promo"
          src="https://distriboissons.be/actualites/horeca/index.html"
          frameBorder="0"
        ></iframe>
      </section>

      <section className="bg_primary color_light">
        <Wrapper>
          <Block blockKey="block7" />
        </Wrapper>
      </section>
    </>
  )
}

export default Promos
