import React, { useContext } from 'react'
import { ThemeContext } from '../data/contextPopup'
import close from '../assets/icon/close.svg'

const ButtonPopup = ({ textButton, style }) => {
  const text_Button = textButton
  const classe = style
  const { setPopupStatus } = useContext(ThemeContext)

  return (
    <button
      className={
        !text_Button || text_Button === '' ? '' : 'mt-4 button ' + classe
      }
      onClick={setPopupStatus}
    >
      {text_Button ? (
        text_Button
      ) : (
        <img
          src={close}
          className="cursor-pointer"
          title="Fermer"
          alt="Fermer"
        />
      )}
    </button>
  )
}

export default ButtonPopup
