import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

function generateGoogleMapLink(dateString) {
  const encodedDate = encodeURIComponent(dateString)
  const googleMapLink = `https://www.google.com/maps?q=${encodedDate}`
  return googleMapLink
}

const Article = (props) => {
  const type = props.type
  const environment = process.env.REACT_APP_ENVIRONMENT

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(environment + '/data/' + type + '.php')

        if (!response.ok) {
          throw new Error('Problème de récupération des données')
        }

        const result = await response.json()
        setData(result)
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [environment, type])

  if (loading) {
    return <div>Chargement...</div>
  }

  if (error) {
    return <div>Erreur : {error}</div>
  }

  return (
    <section className="py-8 w-10/12 md:w-6/12 m-auto">
      {data.map((article, index) => (
        <div
          id={article.cafesalouer_id}
          key={`cafe-` + index}
          className="w-12/12 m-4 flex flex-col md:flex-wrap items-center bg-white border border-gray-200 rounded-lg shadow-sm md:flex-row md:max-w-4xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <img
            alt={article.cafesalouer_titre}
            tiltle={article.cafesalouer_titre}
            src={
              `https://distriboissons.be/admin/img/cafesalouer/` +
              article.cafesalouer_img +
              '.' +
              article.cafesalouer_format
            }
            className={
              'object-cover w-full rounded-t-lg h-65  md:w-6/12 md:rounded-none md:rounded-s-lg'
            }
          />
          <div className="flex flex-col items-start p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {article.cafesalouer_titre}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              {article.cafesalouer_description}
            </p>
            {article.cafesalouer_prix ? (
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                Prix : {article.cafesalouer_prix}
              </p>
            ) : null}
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              <a className="underline" href={article.cafesalouer_facebook}>
                Facebook
              </a>
            </p>
            <Link
              to="/contact"
              className="inline-block py-4 button button--secondary"
            >
              Contactez-nous
            </Link>
            <a
              className="inline-block button"
              target="_blank"
              rel="noreferrer"
              href={generateGoogleMapLink(
                article.cafesalouer_adresse + '+' + article.cafesalouer_cp
              )}
            >
              Afficher la carte
            </a>
          </div>
        </div>
      ))}
    </section>
  )
}

export default Article
