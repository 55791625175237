import React from 'react'

import Slide from './Slide'
import Wrapper from './Wrapper'
import Block from './Block'
import FaqTwoo from './FaqTwoo'
import Title from './Title'
import BlockBg from './BlockBg'

const Services = () => {
  return (
    <>
      <section className="relative">
        <Title blockKey="slider_service" />
        <Slide blockKey="slider_service" />
      </section>
      <section className="md:flex items-stretch flex-row-reverse">
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="carte_avantage" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_secondary color_light">
          <BlockBg blockKey="carteprivilege" />
        </div>
      </section>
      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="vin_particulier" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="bouchon" />
        </div>
      </section>
      <section className="md:flex items-stretch flex-row-reverse">
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="chargement" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="chargement_service" />
        </div>
      </section>
      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="degustations_particulier" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="degustations_particulier" />
        </div>
      </section>
      <section className="md:flex items-stretch flex-row-reverse">
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="assortiment" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="commandes" />
        </div>
      </section>
      <section className="md:flex items-stretch">
        <div className="md:w-1/2 bg_primary color_light">
          <Wrapper>
            <Block blockKey="invendus" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="invendus" />
        </div>
      </section>
      <section className="md:flex items-stretch flex-row-reverse">
        <div className="md:w-1/2 bg_secondary color_light">
          <Wrapper>
            <Block blockKey="panier" />
          </Wrapper>
        </div>
        <div className="md:w-1/2 bg_primary color_light">
          <BlockBg blockKey="panier" />
        </div>
      </section>
      <section className="pt-16 text-center color_secondary">
        <Block blockKey="magasins" />
      </section>
      <section className="pt-8 text-center color_secondary">
        <FaqTwoo blockKey="faq3" />
      </section>
    </>
  )
}

export default Services
